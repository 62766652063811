<template>
  <div>
    <div class="infotDetai">
      <div class='title-cell'>
        <div class='big-title'>江苏南通人力资源服务产业园</div>
        <div class='small-title'>JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK</div>
      </div>
      <input class="input" v-model.trim="phone" maxLength="11" placeholder="请输入手机号" />
      <div class="code">
        <input class="input" v-model.trim="smsCode" placeholder="请输入验证码" />
        <div class="codeval">
          <p class="validation" v-if="!setTimeInterval" @click.stop="codeClick">{{ changeVerificationButton }}</p>
          <p class="validation" v-else>{{ changeVerificationButton }}</p>
        </div>
      </div>
      <input class="inputpass" type="password" v-model.trim="pwd" placeholder="请输入密码" />
      <p class="text">6-16个字符，区分大小写</p>
      <input class="input" type="password" v-model.trim="pwdConfirm" placeholder="请再次输入密码" />
      <p class="check">
        <label for="label" @change="changeclick">
          <input id="label" type="checkbox" name="vehicle" value="Car" v-model="check" />
          <span>我已阅读并同意南通市人力资源产业园</span>
          <span class="color178" @click="template">《用户协议》</span>
        </label>
      </p>
      <button class="login active" @click="onSubmit">确定</button>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui' // 引入提示弹窗组件
import { getCode, Essentialregister } from '@/api/login'

export default {
  data() {
    return {
      times: 60,
      changeVerificationButton: '获取验证码',
      setTimeInterval: false,
      phone: '',
      passwd: '',
      newpasswd: '',
      check: true,
      errText: '',
      smsCode: '', // 验证码
      pwd: '', // 密码
      pwdConfirm: '', // 确认密码
      codeFalse: true // 获取验证码防抖动参数
    }
  },
  created() {},
  methods: {
    // 帮助中心
    template() {
      this.$router.push({
        path: '/template',
        query: { code: 'user_agreement' }
      })
    },
    backClick() {
      this.$router.go(-1)
    },
    changeclick() {
      console.log(this.check)
    },
    // 判断账户是否存在
    codeClick() {
      if (this.codeFalse) {
        this.codeFalse = false
        const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!this.phone) {
          this.errText = '请输入手机号'
          this.showError()
          this.codeFalse = true
          return false
        } else if (this.phone !== 11 && !myreg.test(this.phone)) {
          this.errText = '请输入正确的手机号'
          this.showError()
          this.codeFalse = true
          return false
        }
        Indicator.open()
        getCode({
          mobile: this.phone,
          smsmode: '1'
        })
          .then((res) => {
            Indicator.close()
            console.log(res)
            const { success, message } = res

            if (success) {
              this.smsCode = res.result
              this.errText = '发送成功'
              this.showError()

              const authTimetimer = setInterval(() => {
                this.codeFalse = true

                if (this.times <= 0) {
                  this.setTimeInterval = false
                  this.changeVerificationButton = '获取验证码'
                  this.flag = true
                  this.times = 60
                  clearInterval(authTimetimer)
                } else {
                  this.setTimeInterval = true
                  this.changeVerificationButton = this.times + '秒之后重新发送'
                  this.times--
                }
              }, 1000)
            } else {
              this.errText = message
              this.showError()
            }
          })
          .catch((err) => {
            Indicator.close()
            this.codeFalse = true
            this.requestFailed(err)
          })
      } else {
        this.errText = '你点的太快了'
        this.showError()
      }
    },
    onSubmit() {
      const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!this.phone) {
        this.errText = '请输入手机号'
        this.showError()
        return false
      } else if (this.phone !== 11 && !myreg.test(this.phone)) {
        this.errText = '请输入正确的手机号'
        this.showError()
        return false
      }
      if (!this.smsCode) {
        this.errText = '请输入验证码'
        this.showError()
        return false
      } else if (this.smsCode.length !== 6) {
        this.errText = '请输入6位验证码'
        this.showError()
        return false
      }
      if (!this.pwd) {
        this.errText = '请输入密码'
        this.showError()
        return false
      } else if (this.pwd.length < 6 || this.pwd.length > 16) {
        this.errText = '请输入6到16位的密码'
        this.showError()
        return false
      }
      if (!this.pwdConfirm) {
        this.errText = '请输入确认密码'
        this.showError()
        return false
      } else if (this.pwdConfirm !== this.pwd) {
        this.errText = '确认密码与原密码不匹配'
        this.showError()
        return false
      }
      if (this.check === false) {
        this.errText = '请勾选用户协议'
        this.showError()
        return false
      }
      Essentialregister({
        phone: this.phone,
        pwd: this.pwd,
        pwdConfirm: this.pwdConfirm,
        smsCode: this.smsCode,
        type: 'personal'
      })
        .then((res) => {
          const { success, message } = res
          this.errText = message
          this.showError()
          if (success) {
            this.$router.push({
              name: 'login'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 错误请求
    requestFailed(err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    },
    // 弹窗提示
    showError() {
      const instance = Toast({
        message: this.errText,
        position: 'middle',
        duration: 5000
      })
      setTimeout(() => {
        instance.close()
      }, 3500)
    }
  }
}
</script>

<style lang="less" scoped>
.infotDetai {
  margin-top: 90px;
  padding: 0 15px;
  text-align: center;

  img {
    margin-bottom: 20px;
  }

  .title-cell{
    margin-bottom: 20px;

    .big-title{
      font-size: 22px;
      font-weight: bold;
      line-height: 40px;
    }

    .small-title{
      font-size: 16px;
      line-height: 30px;
    }
  }

  .code {
    position: relative;

    .codeval {
      position: absolute;
      display: inline-block;
      top: 10px;
      right: 55px;
      color: #178cf9;
      font-size: 15px;
    }
  }

  .input {
    width: 250px;
    height: 38px;
    margin-bottom: 20px;
    background: #f5f5f5;
    line-height: 38px;
    padding-left: 10px;
    color: #333;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid #f5f5f5;
  }

  .inputpass {
    width: 250px;
    height: 38px;
    background: #f5f5f5;
    line-height: 38px;
    padding-left: 10px;
    color: #333;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid #f5f5f5;
  }

  .text {
    color: #999;
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-left: 60px;
    text-align: left;
  }

  .check {
    color: #999;
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-left: 40px;
    text-align: left;
    vertical-align: middle;

    input {
      vertical-align: middle;
      margin: 0;
    }

    span {
      vertical-align: middle;
    }

    .color178 {
      color: #178cf9;
    }
  }

  .login {
    width: 250px;
    height: 38px;
    background: #999;
    line-height: 38px;
    padding-left: 10px;
    color: #fff;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid #f5f5f5;
    text-align: center;
    margin-top: 30px;
  }

  .active {
    background: #178cf9;
    color: #fff;
  }

  .login:link,
  .login:hover,
  .login:visited,
  .login:active,
  .login:focus {
    border-radius: 50px;
  }
}
</style>
